import React from 'react';
import { Helmet } from 'react-helmet';

const IndexPage: React.FC = () => {
    return <div>
        <Helmet>
            <title>하이소사이어티 이용약관 (2019.01.20 시행)</title>
            <meta name="viewport" content="width=device-width, user-scalable=no" />
        </Helmet>
        <div style={{ padding: 20 }}>
            <h2>하이소사이어티 이용약관 (2019.01.20 시행)</h2>
            <p>하이소사이어티 서비스 이용 약관은 관계법령의 규정을 반영하여 아래와 같이 내용을 정하고 있습니다.</p>
            <p>이 이용 약관은 전체 웹 사이트 및 모바일용 웹사이트 등 (주)누카(이하 "회사")에서 제공하는 '하이소사이어티(High Society)' 서비스에 적용되는 것으로 이용자와 회사 간에 적용됩니다.
            따라서 서비스 이용 전에 전체 내용을 주의해서 읽어 보십시오. 회사는 아래의 내용에 동의하시는 이용자에 한하여 정상적인 서비스를 제공할 수 있습니다. 내용에 동의하지 않으면 '하이소사이어티'에서
          제공하는 모든 서비스에 대한 이용이 불가능합니다.</p>
            <h3>제1조 목적</h3>
            <p>이 약관은 회사가 제공하는 온라인 소개팅 주선 서비스 이용과 관련하여 회사와 이용자의 사용 시 권리와 의무, 책임사항 등 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
            <br />
            <h3>제2조 용어의 정의</h3>
            <p>가. 이용자: 회사의 서비스를 이용하기 위해 가입 등을 하여 약관에 따른 권리와 의무를 갖는 자를 말합니다.</p>
            <p>나. 서비스: 서비스라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 제공 형태(웹사이트, 모바일 어플리케이션 등)에 상관없이 이용자가 이용할 수 있는
          '하이소사이어티' 및 관련 제반 서비스를 의미합니다.</p>
            <p>다. 매칭: ‘하이소사이어티’가 제공하는 소개팅 관련 서비스를 이용하고자 가입한 각 이성 간 정보가 서로에게 공개되며 연결되는 것을 의미합니다. 매칭 성공은 양 측이 호감 의사를 표시하거나 일방이
          호감을 보내고 상대방의 수락함으로 이루어지며 매칭 성공이 이루어진 후 양 측에게 전화번호를 공개합니다.</p>
            <p>라. 가입: 이용자가 온라인 소개팅 주선 서비스를 이용하기 위해 본인에 대한 간단한 정보를 입력하여 가입 신청을 하는 것을 의미합니다. '하이소사이어티' 이용을 위해 이용자는 사실만을 신청서에 기재해야
          합니다. 이용자가 허위나 부정한 정보를 입력하여 서비스를 이용하는 경우 회사는 이에 관하여 법적 책임을 부담하지 않습니다. </p>
            <p>마. 인증: 이용자가 '하이소사이어티' 서비스 이용 신청 시, 회사는 이에 대한 간단한 심사를 통해 가입자격 인증을 요청 할 수 있습니다. 성별에 따라 일부 이용자의 경우, 자격 인증 이후 소개팅 매칭
            참여가 가능합니다. 이에 해당하는 이용자는 본인의 실명 등을 토대로 회사가 제시하는 소정의 방법을 통하여 가입자격 인증을 해야 할 의무가 있습니다. 해당 가입자격에 해당하는지 인증을 위해
            '하이소사이어티'는 각 이용자의 '소속 직장 웹메일을 통한 인증 서비스', '직장명함', '재직증명서' 등의 증빙서류 제출을 통한 '인증 서비스'를 제공합니다. 또한 이용자의 선택에 따라, 연수입,
            재산, 본인 소유 차량 등을 인증하기 위해 각 이용자의 '소득확인증명서', '연봉계약서', '등기부등본', '차량등록증' 등의 증빙서류 제출을 통한 '인증 서비스'를 제공합니다. 이용자가 허위나 부정한
            정보를 입력하여 서비스를 이용하는 경우 회사는 이에 관하여 손해배상 또는 기타 법적 책임을 부담하지 않습니다. 또한 '하이소사이어티'는 직장의 웹 메일을 통한 인증 여부나 명함, 재직증명서,
            소득확인증명서, 연봉계약서, 등기부등본, 차량등록증 등을 통한 인증 여부 확인만을 제공할 뿐 해당 이용자가 실제 해당 직장의 재직중인 직장인인지에 대한 확인, 제출한 서류에 해당되는 재산, 소득 등에
          대한 확인 책임은 이용자에게 있으며, 회사는 이에 관하여 어떠한 손해배상 또는 기타 법적 책임도 부담하지 않습니다.</p>
            <br />
            <h3>제3조 약관의 효력</h3>
            <p>가. 효력 발생: 이용자가 가입 신청함과 동시에 효력이 발생합니다. 관계법령에 위배되지 않는 범위 안에서 개정이 될 수 있으며, 이는 서비스 공지사항, 홈페이지 게시 등 기타 수단으로 통보하여 효력을
          인정 받습니다.</p>
            <p>나. 이용자의 이 약관에 대한 동의는 '하이소사이어티' 웹 사이트나 모바일 웹 사이트 또는 모바일 어플리케이션 등을 방문하여 약관의 변경 사항을 확인하는 것에 대한 동의를 포함합니다.</p>
            <p>다. 이용자는 개정된 약관에 동의하지 않을 경우 '하이소사이어티' 에게 계약해지 및 이용중지를 요청 할 수 있으며, 계속 서비스를 사용할 경우 변경된 약관에 동의하는 것으로 간주됩니다.</p>
            <br />
            <h3>제4조. 약관 외 준칙</h3>
            <p>이 약관에 명시되지 않은 사항은 전자거래기본법, 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 기타 관련법령의 규정에
          따릅니다.</p>
            <br />
            <h3>제5조 서비스 이용 신청</h3>
            <p>가. 서비스를 이용하고자 하는 자는 “약관에 동의” 후 신청자의 각종 정보(주소, 학력, 면허, 자격증 등) 입력을 완료하여 서비스 이용을 신청합니다.</p>
            <p>나. 이용자가 입력한 정보는 사실로 간주되며 회사는 이용자가 입력한 정보의 내용이 사실과 다를 경우(허위정보 등)와 타인의 정보를 도용한 것으로 의심되는 경우, 사실 여부를 확인하기 위해 이용자에게
            입증자료 제출 및 해명을 요구할 수 있으며 상이한 사실이 명백하게 확인 되는 경우, ‘하이소사이어티’은 이용자로부터 이용자의 권한을 박탈하며 서비스 이용을 전면적으로 거부 할 수 있고, 이로 인해
          발생하는 모든 불이익에 대해서는 회사가 책임지지 않습니다.</p>
            <p>다. 회사는 이용자의 상태와 이용기간, 이용횟수, 서비스 접근 방식 등에 따라 합리적인 기준을 통해 서비스 범위에 차등을 둘 수 있으며, 이용자는 상기 내용에 따라 서비스 이용에 제약을 받을 수
          있습니다.</p>
            <p>라. 회사는 '가'항의 방법으로 서비스 이용을 신청한 자가 아래의 각 호에 해당하는 경우 서비스 이용 신청 승낙을 유보 또는 거부할 수 있습니다.</p>
            <p>1. 타인의 정보 도용, 사진 도용, 프로필 허위기재 등 등록내용을 허위로 기재한 사실이 있는 경우, 해당 대학교의 재학생 또는 졸업생 또는 해당 직장에 재직중인 직장인이 아닌
          경우, 가입조건 페이지에 기재된 가입조건에 해당하지 않는 경우.</p>
            <p>2. 이용자가 ‘하이소사이어티’ 약관 및 서비스 이용에 관한 관계법령을 위반하여 ‘하이소사이어티’ 서비스의 이용자격을 상실한 경우.</p>
            <p>3. 한국 나이 기준 만19세 이상이 아닌 경우.</p>
            <p>4. 법률혼 및 사실혼 상태이거나 이에 준하는 자.</p>
            <p>5. 사회질서 및 미풍양속을 문란하게 한 자.</p>
            <p>6. 영리 추구를 목적으로 ‘하이소사이어티’ 서비스에 가입 하고자 하는 자.</p>
            <p>7. 금치산자 및 한정치산자 기타 이에 준하는 자.</p>
            <p>8. 기타 회사의 여건상 이용승낙이 곤란하거나 가입결격 사유에 해당하는 자.</p>
            <p>마. 이용 신청 계약 성립 시점은 회사의 이용 신청 승낙이 신청자에게 도달한 시점을 기준으로 합니다</p>
            <br />
            <h3>제6조 이용 신청 심사</h3>
            <p>가. '하이소사이어티'에서는 일정한 심사 과정을 통과한 가입자들을 중심으로 서비스하게 됩니다. 따라서 심사를 완료하지
            못한 가입신청자들은 서비스 이용에 제한이 있을 수 있으며 해당 상태의 신청자에게는 SMS,
          App내 푸쉬알림, 이메일, 전화 등의 수단으로 심사 통과를 돕기 위한 행위가 이루어 집니다.</p>
            <p>나. 심사 과정에서 프로필 상의 문제는 없어 심사는 통과하였으나 성별, 지역, 연령에 따라 '하이소사이어티'가 정한
            성비 또는 기타 기준에 맞지 않아 가입 대기 상태인 신청자들에 한하여 별도의 서비스를 제공할
          수 있습니다.</p>
            <p>다. 심사는 회사에서 정한 운영방침에 따라 이루어지며 관리자 즉, 사람이 직접 하기에 이용 신청 이후 일반적으로 3일
            이내(평일 기준)의 심사기간이 필요할 수 있습니다. 단 위 기간은 회사의 상황에 따라
            변경될 수 있습니다. 또한 회사에서 안정적인 서비스 제공을 위해 필요하다고 생각하는 경우에는 심사통과를 보류하거나
          확인을 위한 제증명을 요구할 수 있습니다.</p>
            <p>라. 회사는 신청자가 작성한 닉네임이 1) 개인정보 유출 우려가 있거나, 2) 반사회적 또는 미풍양속에 어긋나거나,
            3) 회사 및 회사의 운영자로 오인될 우려가 있거나, 4) 작성과정 상의 문제로 인해
            제대로 입력되어있지 않은 경우 해당 닉네임에 대한 사용을 제한 하거나, 관리자가 해당 닉네임을 수정 및 삭제할 수
          있습니다.</p>
            <br />
            <h3>제7조 서비스의 종류 및 범위</h3>
            <p>가. 이용자는 무료로 ‘하이소사이어티’ 서비스 이용을 신청(가입) 할 수 있습니다. 추후 가입 유료전환시엔 일정기간 사전공지 후 변경이 이루어지게 됩니다.</p>
            <p>나. 신청 후, 운영 상의 심사 및 절차를 마친 신청자는 무료로 매칭 서비스를 제공 받을 수 있으며 이때 도착한 상대의 프로필은 실명과 연락처가 공개되지 않은 채로 소개받는
          것입니다.</p>
            <p>다. 서비스는 연중무휴, 365일 24시간 제공을 원칙으로 하되, 이용자(신청자)의 접속 폭주와 기타 회사 사정에 의해 지연될 수 있으며 이에 대해 회사는 손해배상 등의 책임을
          부담하지 않습니다.</p>
            <p>라. 유료 부가서비스</p>
            <p>1. 상대방에게 ‘호감보내기’를 하기 위해서는 결제가 필요할 수 있으며, 이 외에 추가 기능과 기타 부가 서비스를 이용하려면 별도의 결제가 필요할 수 있습니다.
      </p>
            <p>2. 호감보내기 및 유료 부가서비스 이용을 위한 결제에 있어서는 서비스 이용 신청과는 별도의 추가적인 결제과정을 거치며 신청자의 동의 하에 결제하도록 합니다.</p>
            <p>3. 사용기한이 별도로 명시된 유료 부가서비스의 경우는 구매 시 명기한 사용기한을 따릅니다. 단, 회사가 이용자(신청자)에게 지급한 무료 부가서비스의 경우에는 서비스 사용기한을
          보증하지 않습니다.</p>
            <p>4. 사용기한이 ‘영구’로 표시된 부가서비스나 유효기한이 표시되지 않은 부가서비스에 대한 사용은 서비스가 정상적으로 제공되는 기간 동안의 이용을 보장하는 것이며, 만약 이용자가
            서비스 이용약관상 금지 행위를 하였거나 휴면 계정으로 분류되어 그에 따른 제한으로 매칭 이용제한의 대상이 되는 경우 또는 하단의 제9조 '나'항에 따라 서비스가 중단되는 경우에는,
            영구 또는 유효기간이 표시되지 않은 유료 부가서비스의 사용도 중지되며 회사는 이용자에 대하여 구매대금을 반환할 책임을 부담하지 아니합니다. 그러나 회사 측의 중대한 과실이 있는
          경우에는 해당 부분에 대하여 관련 기관(소비자보호관련기관 및 관할법원 등) 에서 권장하는 고지 기준에 따른 환불을 제공합니다.</p>
            <p>5. 회사는 부가서비스의 사용기한 중 회사의 서비스 내용의 변경 등에 대한 정책에 따라 기존 서비스의 기능을 변경하거나, 사용이 불가능하도록 변경할 수 있습니다. 이 때 유료로
            구매한 이용자에 한해 기존 부가서비스의 잔여 기간을 산정한 뒤 등가의 신규 부가서비스나 기존 부가서비스로 보상하며, 이러한 보상이 불가능할 경우 관련 기관(소비자보호관련기관 및
          관할법원 등) 에서 권장하는 고지 기준에 따른 환불을 제공합니다.</p>
            <p>마. 회사에서는 양질의 서비스 제공을 위해 신청자 프로필의 수정 및 보완을 요청할 수 있으며 당사의 내부 규정에 따라 부적합한 프로필을 작성한 신청자에게는 서비스 이용을 제한 할 수
            있습니다. 또 이외에 ‘하이소사이어티’ 내 매칭에서 상습적으로 다수의 회원에게 반복적으로 보이는 무분별한 호감보내기 메시지 전송 등으로 진실된 인연을 찾고자 하는 목적이 아닌
            것으로 보이는 행위 (구체적인 판단기준은 1차적으로 하이소사이어티 운영팀의 판단에 따릅니다. 다만 이에 이의가 있을 시에는 소비자보호관련기관, 분쟁중재기관, 관할 법원 등의 판단에
          따를 수 있으며, 최종 결정이 내려지기 전까지는 하이소사이어티 운영팀의 판단에 따르도록 합니다.)를 하는 경우, 서비스 이용을 제한할 수 있습니다.</p>
            <p>바. 회사는 신청자가 입력한 프로필 정보에 대해 신원 인증을 요청할 수 있습니다. 프로필 정보에 대한 신원 인증은 회사가 정한 신원인증서류를 신청자가 회사에 제출하거나, 신청자가
            자신이 입력한 프로필 정보에 대해 재확인 및 동의 절차를 거치는 방법으로 이뤄집니다. 신원 인증의 방법과 절차에 따라 별도의 신원 인증비를 신청자에게 요청할 수 있습니다.
            신원인증일 이후부터 서비스 이용일 현재까지의 개인정보의 정확성에 대해서는 회사가 알지 못하며, 신청자 본인의 개인정보에 대한 정확하고 최신의 개인정보로의 유지, 갱신의 의무는
            신청자 본인에게 있습니다. 이로 인해 발생한 문제의 책임은 신청자에게 있으며 회사는 책임을 지지 않습니다. 또한 회사는 관련 문제로부터 발생한 대내외적 손실에 대한 법적 책임을
          해당 신청자에게 물을 수 있습니다.</p>
            <p>사. 회사가 제공하는 ‘하이소사이어티’ 서비스는 ‘하이소사이어티’의 웹사이트 및 모바일 어플리케이션, 이메일 및 SMS, App내 푸쉬알림 등을 통하여 매칭, 매칭 후 프로필 교환
          및 성공 (상호 호감보내기) 시 연결된 상대방의 “닉네임과 연락처”를 제공하는 것과 관련된 범위 전반으로 합니다.</p>
            <p>아. ‘하이소사이어티’에서 매칭된 후 이용자들간 행위(연락 및 만남)의 책임은 당사자에게 있으며 회사는 책임을 지지 않습니다.</p>
            <p>자. 회사는 ‘하이소사이어티’에서 연결된 뒤에도 서비스를 악용하거나 약관 혹은 법령상의 제한을 위반하는 이용자의 서비스 이용을 제한하기 위하여 고객센터를 운영하고 있습니다. 이용자
            간의 만남에 있어 이용자들간의 행위의 책임은 해당 이용자에게 귀속되지만, 고객센터로 신고가 접수되고 신고된 이용자의 행위가 추후 다른 이용자에게 추가적인 피해를 입힐 수 있다고
          판단되는 경우에는 문제의 이용자들에 대하여 이용 제한, 이용 영구불능조치, 법적 책임 추궁 등의 후속 조치를 취할 수 있습니다.</p>
            <br />
            <h3>제8조 결제, 환불 및 결제 취소</h3>
            <p>가. 이용자는 회사가 제공하는 다양한 결제수단을 통해 유료서비스를 이용할 수 있으며, 결제가 비정상적으로 처리되어
          정상처리를 요청할 경우 회사는 이용자의 결제금액을 정상처리 할 의무를 집니다.</p>
            <p>나. 회사는 부정한 방법 또는 회사가 금지한 방법을 통해 충전 및 결제된 금액에 대해서는 이를 취소하거나 환불을
          제한할 수 있습니다.</p>
            <p>다. 이용자는 다음 각 호의 사유가 있으면 아래의 규정에 따라서 회사로부터 결제 취소, 환불 및 보상을 받을 수
          있습니다.</p>
            <p>1. 결제를 통해 사용할 수 있는 서비스가 전무하며 그에 대한 책임이 전적으로 회사에 있을 경우 (단, 시스템 정기
          점검 등의 불가피한 사유로 인한 경우는 제외)</p>
            <p>2. 회사 또는 결제대행사의 시스템 오류로 인하여 결제가 중복으로 이루어진 경우</p>
            <p>3. 서비스 중단 등 회사의 잘못으로 인해 회사가 이용자에게 해지를 통보하는 경우</p>
            <p>4. 기타 소비자 보호를 위하여 당사에서 별도로 정하는 경우.</p>
            <p>라. 환불 및 결제 취소 절차는 다음 각 항목과 같습니다.</p>
            <p>1. 환불을 원하는 이용자는 고객센터를 통해 이용자 본인임을 인증하는 절차를 거쳐 접수하셔야 하며 본인 인증과 동시에
          환불을 신청하여야 합니다.</p>
            <p>2. 회사는 이용자가 환불 요청 사유가 적합한지를 판단하고 환불사유가 존재하고, 적합한 절차를 거친 것으로 판명된
          경우 이용자에게 결제금액을 환불합니다.</p>
            <p>3. 회사는 이용자에게 환불되어야 할 금액 산정 방식과 절차를 이용자에게 상세히 설명한 후 이용자에게 해당 환불 및
          결제 취소 처리합니다.</p>
            <p>4. 이용자가 구매한 유상 아이템(하이소사이어티 아이템 등)을 일체 사용하지 않은 경우, 결제 후 3일 내에는 전액
            환불이 가능하며, 결제 후 3일 초과시부터는 회사가 정하는 수수료를 차감 후 부분 환불이
          가능합니다.</p>
            <p>5. 이용자가 구매한 유상 아이템(하이소사이어티 아이템 등)을 일부 사용한 경우, 결제 후 3일 이내에는 잔액(잔여)
            아이템 부분에 대하여 이를 산정하여 회사가 정하는 수수료를 차감 후 부분 환불이 가능합니다.
          단, 결제 후 3일 초과시부터는 환불이 불가능합니다.</p>
            <p>6. 위 4, 5항에 해당하는 경우 등 회사의 정책 상 환불 사유에 해당할 경우 회사는 이용자에게 환불을 위한 일정한
            절차 및 환불 입금 계좌번호 등의 금융정보를 이용자에게 요청할 수 있습니다. 이용자가
            이에 정보를 제공하는 경우에 한해 환불이 가능하며, 이용자는 환불 요청시 이러한 개인정보 제공에 동의하는 것으로
          간주합니다.</p>
            <p>마. 이용자의 요청에 의한 환불 처리 시 환불 금액 정산은 결제시점, 잔여 아이템의 수, 아이템 종류에 따라 마련된
          환불정책에 의해 처리됩니다. ((라)항 1-6항 참조)</p>
            <p>바. 이용자가 이용약관과 서비스 및 회사 정책을 위반함으로 인해 이용정지 또는 강제이용중단 조치 되는 경우 환불 및
          보상하지 않습니다.</p>
            <p>사. 이용자의 자진 계정 탈퇴 신청으로 인해 계약이 해지되는 경우, 이용자가 보유한 유료 부가서비스 이용권리는
          자동으로 소멸되어 복구 및 환불이 불가능합니다.</p>
            <p>아. 신원 인증과정에서 이용자의 등록정보가 허위 정보로 판명되거나 이용 조건에 부합되지 않는 것이 판명될 경우 강제
            이용 중지 또는 영구 이용 중지 조치가 되며 이용자 본인의 귀책사유로 인한 것이므로
          환불 및 보상이 불가능합니다.</p>
            <p>자. 회사와 이용자간에 발생한 분쟁은 전자거래기본법 제32조에 의거하여 설치된 기관인 전자거래분쟁조정위원회의 조정에
          따를 수 있습니다.</p>
            <h3>제9조 면책조항</h3>
            <p>가. 회사는 운영상 또는 기술상의 필요에 따라 제공하고 있는 서비스를 변경할 수 있습니다. 변경될 서비스의 내용 및 제공일자 등에 대해서는 회사가 운영하는 홈페이지에 게시하거나 E-mail, SMS,
            App내 푸쉬알림 등으로 이용자에게 통지합니다. 단, 회사가 사전에 통지할 수 없는 치명적인 버그 발생, 서버기기결함, 긴급 보안문제 해결 등의 부득이한 사정이 있는 경우에는 사후에 통지할 수
          있습니다.</p>
            <p>나. 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</p>
            <p>1. 전시, 사변, 천재지변 또는 국가 비상사태 등 불가항력적인 사유가 있는 경우.</p>
            <p>2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우.</p>
            <p>3. 서비스용 설비의 보수 또는 공사 등 부득이한 사유가 있는 경우.</p>
            <p>4. 회사의 제반 사정으로 서비스를 할 수 없는 경우.</p>
            <p>다. 회사는 서비스 개편이나 운영상 또는 회사의 긴박한 상황 등에 의해 서비스 전부나 일부를 장기간 중단(또는 종료)할 필요가 있는 경우 최소 7일 전에 홈페이지에 이를 공지하고 서비스의 제공을 중단할
            수 있습니다. 이용자는 서비스 전부 혹은 일부의 종료 시 사용 기간이 남아 있지 않는 유료 부가서비스 대하여 배상을 청구할 수 없습니다. 무료로 제공된 영구 이용 가능 유료부가서비스의 경우,
          서비스 중단 공지 시 공지된 서비스의 종료일까지를 해당 부가서비스의 이용기간으로 봅니다.</p>
            <p>라. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 운영을 하지 못해 발생하는 문제에 대하여 책임이 면제됩니다.</p>
            <p>마. 회사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대한 책임을 지지 않습니다.</p>
            <p>바. 회사는 이용자가 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나, 서비스 내용을 숙지하지 못하여 발생하는 손해 등에 대해서는 책임을 지지 않습니다.</p>
            <p>사. 회사는 이용자가 작성한 신청서(프로필 등) 대해 신뢰도 및 정확도 대한 책임을 지지 않으며 이로 인해 발생한 정신적, 물질적 손해 등의 피해에 대한 책임이 면제됩니다.</p>
            <p>아. 회사의 서비스 내 게시판의 게시물의 진실성이나 명예훼손, 저작권 위반과 같은 문제가 발생하여 벌어지는 민•형사 상의 모든 책임은 이용자 본인에게 있습니다.</p>
            <p>자. 회사는 이용자의 서비스 이용에 필요한 서버의 보수로 인한 교체, 일시 정지, 개편 작업이 발생할 경우에는 서비스 중지에 대한 책임이 면제됩니다.</p>
            <p>차. 이용자가 서비스 중인 홈페이지의 공지사항 등을 확인해야 하는 의무를 게을리하여 그 내용을 숙지하지 못하여 발생하는 문제의 책임은 이용자 본인에게 있습니다.</p>
            <p>카. 회사는 이용자(신청자)의 신원을 보증하지 않습니다. 매칭 상대의 해당 대학의 재학생 또는 졸업생, 해당 직장에 재직중인 직장인인지 여부의 최종 확인 책임은 이용자에게 있습니다. 또한 이용자가
            다른 이용자에게 경제적, 정신적, 물질적 피해를 가해도 그 책임은 가해자인 이용자에게 있습니다. 단, 회사는 이에 대한 수사기관의 수사, 재판, 분쟁 등이 발생한 경우 이에 대한 최대한의 도움을
          지원합니다.</p>
            <p>타. 회사의 사이트에 배너광고 등으로 게재된 제3의 사이트 주소로 링크된 곳에서 발생하는 현상에 대해 회사는 어떠한 통제권도 가지지 않으며 해당 사이트의 운영방침에 따릅니다. 이로 인하여 발생하는
          문제에 대한 책임은 해당 사이트와 이용자 본인에게 있습니다.</p>
            <p>파. 회사는 이용자가 본 약관 규정을 위배하여 발생한 손실에 대해서는 책임을 지지 않습니다.</p>
            <p>하. 이용자가 공개를 허락한 자료에 대해 발생되는 모든 문제에 대해서는 회사의 책임이 면제 됩니다. (‘하이소사이어티’은 온라인 공간을 통해 이용자의 프로필을 매칭 상대에게 전달합니다. 이용자 및
          상대 이용자의 고의 또는 과실로 인해 이용자의 프로필이 무단 저장되거나 무단 유출되는 것에 대해서 어떠한 책임도 부담하지 않습니다.)</p>
            <p>갸. 회사의 동의 없이 제3자에게 이용자의 권한을 판매 및 양도할 경우 이용자 자격 박탈 및 민사청구 및 형사상의 고소 또는 고발이 가능합니다. 또한 이로 인한 모든 피해는 불법으로 이용자 권한을
          판매/양도한 이용자에게 귀속됩니다.</p>
            <p>냐. 이용자가 작성한 신청서가 본인이 확인 가능한 이메일이 아니거나 연락처 갱신을 하지 않아 회사가 전달하는 공지사항이 그 전달방법인 E-mail과 SMS전송, App내 푸쉬알림 등을 통해
          전달되지 못하여 발생하는 손해에 대한 책임은 이용자 본인에게 있습니다.</p>
            <p>댜. 이용자 간 혹은 이용자와 제3자 상호간의 온라인 및 오프라인상에서 이루어진 행위에 대해서는 이용자 본인에게 책임이 있습니다.</p>
            <p>랴. ‘하이소사이어티’는 가입신청시 대학교 이메일 인증 또는 포털 접속화면, 직장명함, 자동차 등록증, 등기부등본 등과 같은 제출서류 등의 첨부 등을 통해 관리자가 직접 인증 여부를 수작업으로
            확인함으로써 가입 자격 대상에 해당하는지를 확인합니다. 그러나 이용자가 타인의 계정을 도용한 경우 또는 해당 대학의 교직원 등 기타 자격으로 재학생 또는 졸업생이 아닌 경우, 관련 서류를 위조 또는
          대여한 경우 등의 경우가 발생할 수 있습니다. 이에 대한 모든 최종 확인 책임은 이용자 본인에게 있으며 회사는 이에 대한 책임을 부담하지 않습니다.</p>

            <br />
            <h3>제10조 이용자의 의무</h3>
            <p>서비스 이용 신청 시 신청자는 반드시 동 약관 ‘제2조’에 기재되어있는 인증을 거친 해당 거주지역의 거주자이거나 해당 자동차의 소유주이거나 해당 자산의 소유주, 혹은 해당 소득을 벌어들이는 자여야
            합니다. 또한 작성하는 개인 정보와 프로필(사진과 간단한 소개 등)은 사실이어야 합니다. 일정기간이 경과한 뒤 변경되는 개인 정보는 지속적으로 본인이 정보를 갱신하여야 합니다. 이에 성실히 이행하지
          않음으로 인해 발생하는 불이익은 이용자 (신청자) 본인의 책임입니다.</p>
            <p>가. 이용자(신청자)가 직접 입력한 개인정보는 진실하여야 하며 만일 이용자(신청자)가 허위 기타 부정한 정보를 입력하여 다른 이용자에게 피해가 발생할 경우 그에 대한 민·형사상의 책임을 포함한 일체의
          책임은 이용자 본인에게 귀속됩니다.</p>
            <p>나. 회사는 이용자가 기재한 학력, 거주지, 보유 자산 등 기타 정보에 대한 보증을 하지 않습니다. 또한, 이용자간의 만남에 발생한 문제의 책임은 전적으로 이용자 본인에게 귀속되기에 신중히 검토한 후
          서비스를 이용해야 합니다.</p>
            <p>다. 이용자는 항상 ‘하이소사이어티’의 공지 내용을 숙지하여야 합니다. 위 의무를 성실하게 이행하지 않음으로 인해 발생한 문제에 대한 책임은 이용자에게 귀속됩니다.</p>
            <p>라. 이용자는 아래 각 호에 해당하는 행위를 해서는 안됩니다.</p>
            <p>1. ‘하이소사이어티’ 사이트의 이용자(신청자)정보를 부정하게 취득하는 행위.</p>
            <p>2. ‘하이소사이어티’ 홈페이지 등을 해킹 또는 유사 프로그램을 이용하여 정상적인 운영을 어렵게 하는 행위 (예: 해킹 또는 바이러스 유포, 디도스 공격 등)</p>
            <p>3. 타인의 휴대전화번호, 개인정보 등을 도용하는 행위.</p>
            <p>4. 개인 정보를 허위 또는 타인의 것으로 등록하는 행위.</p>
            <p>5. 타 이용자에 대한 비방 또는 인격모독을 하거나 이를 작성 및 유포하는 행위.</p>
            <p>6. 공공질서, 미풍양속을 저해하는 저작물을 등록 또는 유통시키는 행위.</p>
            <p>7. 사이트 내에서 불법적으로 물건을 판매하거나 상행위를 하는 행위.</p>
            <p>8. 음란물 및 동영상을 게시하는 행위.</p>
            <p>9. ‘하이소사이어티’이 제공하는 프로필 및 개인 정보(사진, 닉네임, 나이, 간단한 소개 등)을 무단 이메일 회신, 무단 캡처 또는 무단 저장하는 행위 및 이를 유출하여 외부에 게시하는 행위.</p>
            <p>10. ‘하이소사이어티’ 서비스 운영 업무를 방해하는 행위 또는 ‘하이소사이어티’에 대한 허위 사실을 유포하는 등의 행위.</p>
            <p>11. ‘하이소사이어티’ 내 익명게시판 등에서 타인을 비방하거나 불쾌한 게시물을 작성, 논란을 일으키거나 회사의 서비스 제공에 유해한 게시물을 작성하는 등 회사가 정한 게시판 이용 정책을 위반하는 행위.
      </p>
            <p>12. 기타 ‘하이소사이어티’ 서비스 및 제반 설비를 이용하여 범죄 또는 불법행위를 하거나, 이외에 하이소사이어티 운영팀(관리자)의 판단 하에 이용에 부적절하다고 여겨지는 행위.
          이상 위 d항 각 호 해당 사항에 명시한 내용을 위반한 이용자는 강제 이용 중단 및 영구 이용 중단 처리될 수 있으며 민 형사상의 책임을 지게 됩니다.</p>
            <p>마. ‘하이소사이어티’에서 허용한 정당한 호감보내기 결제 서비스 이용을 통해 매칭이 성공되어 상대방의 연락처를 얻는 정상적인 절차가 아닌 ‘하이소사이어티’ 서비스 신청서 영역에 개인의 연락처(
            E-mail, 블로그, 미니홈피, 휴대폰 번호, SNS 등)를 기재 하거나 이를 유도하여 부당하게 본인과의 연락을 할 수 있는 수단을 공개하는 등의 방법으로 ‘하이소사이어티’ 서비스를 부정하게 이용하는
          경우 강제로 이용 중지 처리될 수 있으며 이로 인한 피해에 대해 회사는 책임지지 않습니다.</p>
            <p>바. 본 약관을 위반 하거나, 기타 대한민국 관계 법령에 위반하는 행위에 대해서는 경고 없이 이용자의 권한이 박탈되며, 이에 대해 회사는 이용자에게 어떠한 보상도 하지 않습니다.</p>
            <br />
            <h3>제11조 계약의 종료</h3>
            <p>‘하이소사이어티’은 다음과 같은 조건을 계약의 종료 조건으로 인정합니다.</p>
            <p>가. 이용자의 자의에 의한 이용 중단 신청 (‘하이소사이어티’ 내 웹사이트 내 탈퇴 신청페이지를 통해 자신의 의사를 회사에 피력한 경우 또는 고객센터로 소정의 절차를 통해 탈퇴 또는 서비스 중단을
          요청한 경우)</p>
            <p>나. 이용자의 의무를 성실하게 이행하지 않거나, 약관에서 정한 사항 및 정책과 ‘하이소사이어티’ 고객센터에 위배되는 행위를 한 이용자는 사전 고지 없이 강제 이용 중지 및 영구 이용 중지 처리될
          수 있습니다.</p>
            <p>다. 이용자의 여건상 지속적인 계약 이행이 어렵다고 판단될 경우 임의 매칭 중지 처리할 수 있습니다. (예: 사망 또는 행방불명, 일정 기간 이상 활동을 하지 않는 휴면 이용자 등)</p>
            <p>라. 기타 이용자가 제 5조 d항에 해당하는 행위를 하는 경우 회사에 의해 계약이 종료될 수 있습니다.</p>
        </div>
    </div>
}

export default IndexPage